<template>

 <div class="contailPanelV">
    <div class="intruduce">
      为贯彻落实《新一代人工智能发展规划》（国发〔2017〕35号）和《促进新一代人工智能产业发展三年行动计划（2018-2020年）》
      （工信部科〔2017〕315号）要求，加快推动我国新一代人工智能产业创新发展，聚焦“培育智能产品、突破核心基础、深化发展智能制造、
      构建支撑体系”等重点方向，展示优秀案例。
    </div>
          <page-list
              @pageChanged="handlePageChange"
              :detailPath="detailPath"
              :title="title"
              :list="standardlist"
              :current="pagination.current" 
              :size="pagination.size"
              :total="pagination.total"
          />

  </div>
</template>

<script>
import pageList from "@/components/list/pageList"; //引入内容组件
const category='compliance'

export default {
  name: "ComplianceList",  
  components: {
    pageList, //引入内容组件
  },
  props: {
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 0,
    },
  },
  data() {
    return {
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      detailPath: '/show/compliance/detail',
      standardlist: [], //当前数组
      title: '标准符合性测试列表', //当前标题
      pagination: {
        current: 1, //当前页码
        size: 20, //每页条数
        total: 0, //总数
      },
    }
  },
  created() {
    // console.log(" created  pagination ", this.pagination)
    this.getlist(this.pagination)
  },
  
  mounted() {
    
  },

  methods: {
     //   改编页码之后
    handlePageChange(e) {
      // console.log("xxxx standard list page changed event ",  e)
      this.getlist(e);
    },
    // 获取当前数组
    getlist(pagination) {
      var data = {
        status: 1,
        ...pagination,
      };
      console.log(" get list query ", data)
      this.$api.getCmsInfoPage(category, data).then((res) => {
        console.log("----------- get list result ", res)
        this.standardlist = res.data.records;
        // console.log("   before set pagination ", this.pagination )
        this.pagination.total = res.data.total;
        this.istrue=true
        // console.log("   after set pagination ", this.pagination )
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1000px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0;
      .cardhead {
        padding-left: 0.375rem;
      }
      .cardcent {
        margin-top: 1rem;
        padding-left: 0.375rem;

        .hangbox {
          height: 1.875rem;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0 .9375rem;
      .cardhead {
        padding-left:.675rem;
      }
      .cardcent {
        .hangbox {
          height: 1.8rem;
          margin: 0;
        }
      }
    }
  }
}
</style>
